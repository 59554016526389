import { InputAdornment } from '@material-ui/core';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import { FEATURE_FLAGS } from 'conf';
import { addFlaggedArray, addFlaggedObject } from 'conf/flaggingHelpers';
import useBodyScroll from 'hooks/useBodyScroll';
import get from 'lodash.get';
import mixpanel from 'mixpanel-browser';
import UserModel from 'models/UserModel';
import {
  loadAntiFraud,
  loadCard,
  updateMandatoryChecks,
} from 'modules/profile/actions';
import BorderedTextField from 'modules/shared/components/inputs/BorderedTextField';
import Button from 'modules/shared/components/inputs/Button';
import CloseButton from 'modules/shared/components/inputs/CloseButton';
import Radiobox from 'modules/shared/components/inputs/Radiobox';
import ContentContainer from 'modules/shared/components/v2/ContentContainer';
import AccountTypeSelectDropdown from 'modules/shared/components/v2/Form/SelectDropdown/AccountTypeSelectDropdown';
import AuthorisationTypeSelectDropdown from 'modules/shared/components/v2/Form/SelectDropdown/AuthorisationTypeSelectDropdown';
import EntityTypeSelectDropdown from 'modules/shared/components/v2/Form/SelectDropdown/EntityTypeSelectDropdown';
import {
  FormSubmissionStatus,
  Status,
} from 'modules/shared/components/v2/Form/useFormSubmissionStatus';
import { Wrapper } from 'modules/shared/components/v2/SupplierSetup/styles';
import UnsavedModal from 'modules/shared/components/v2/UnsavedModal';
import { muiTheme } from 'modules/shared/helpers/colorPalettes';
import {
  isFeatureEditEnabled,
  isRegular,
} from 'modules/shared/helpers/headquarterDetect';
import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import {
  getCategoriesRequiringFrontFaceImage,
  getCategoriesRequiringProofOfLife,
} from 'utils/AntiFraudCategoryRule';
import getAccountTypeOptions from 'utils/getAccountTypeOptions';

import CreditCardModal from '../CreditCardModal';
import CategoryRadiobox from './CategoryRadiobox';
import {
  _formatMoney,
  getFormSchema,
  getNewVersionAttribute,
  getOrInitializeAddonModule,
  MAX_CONFIDENCE_SCORE,
  MAX_SIMILARITY_SCORE,
  MIN_CONFIDENCE_SCORE,
  MIN_SIMILARITY_SCORE,
  toNumber,
  useForceUpdate,
} from './helper';
import HistoryLogs from './HistoryLogs';
import { Section, Tab, Tabs, Title } from './styles';
import { AntiFraudProps, Obj, Rule } from './types';
import { getMatchingAutoDecisionRulesets } from './getMatchingAutoDecisionRulesets';
import { ImpactAutoDecisionRulesetPopup } from 'modules/shared/components/widgets/static/ImpactAutoDecisionRulesetPopup/ImpactAutoDecisionRulesetPopup';
import { getRulesetNames } from 'utils/auto-decision';
import { IRuleSetAttributes } from 'modules/addons/auto_decisioning/v2/RulesetContent/type';
import { updateRuleSet } from 'modules/addons/auto_decisioning/actions';

const isCat0Enabled = FEATURE_FLAGS.FEATURE_FLAG_1CAF_CAT_0;
const isCat6Enabled = FEATURE_FLAGS.FEATURE_FLAG_1CAF_CAT_6;

const isValuePresent = (value) =>
  value !== null && value !== undefined && typeof value === 'number';

function AntiFraud(props: AntiFraudProps): ReactElement {
  useBodyScroll();
  const SET_1CAH_PATH = '/dashboard/profile?active_tab=your_team';
  const {
    card,
    closeModal,
    currentUser,
    data,
    dispatch,
    readOnly,
    router,
    tradeAccountLimit,
    autoDecisionRulesets,
  } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [activeTab, setActiveTab] = useState('website');
  const [cardModal, setCardModal] = useState(false);
  const [dirty, setDirty] = useState(false);
  const [isDirtyPopupVisible, setIsDirtyPopupVisible] = useState(false);
  const [dirtyPopUpCallback, setDirtyPopUpCallback] = useState<
    null | (() => void)
  >(() => null);
  const [status, setStatus] = useState<Status | null>(null);
  const [autoDecisionImpact, setAutoDecisionImpact] = useState<{
    isAutoDecisionImpactModalVisible: boolean;
    onConfirm: (() => void) | null;
    rulesetsThatWillBeImpacted: {
      attributes: IRuleSetAttributes;
      id?: string;
    }[];
    tabStatusToBePaused: string | null;
  }>({
    isAutoDecisionImpactModalVisible: false,
    onConfirm: null,
    rulesetsThatWillBeImpacted: [],
    tabStatusToBePaused: null,
  });
  const forceUpdate = useForceUpdate();

  const currentConfig = data.find(
    (addonConfig) => addonConfig.addonModuleName === `anti_fraud_${activeTab}`
  );
  const addonConfig = getOrInitializeAddonModule(currentConfig, activeTab);

  const newVersion = addonConfig.generateNewVersion();
  const {
    authorisation_types,
    auto_pass_min_score,
    category,
    tokenised,
    account_types: accountTypes,
    auto_pass_min_confidence_score,
  } = newVersion.config || {};

  const defaultValues: Rule = {
    ...addFlaggedObject('FEATURE_FLAG_1CAF_ACCOUNT_TYPES', {
      accountTypes: accountTypes || ['cash', 'credit'],
    }),
    authorisationTypes: authorisation_types || [],
    autoPassMinScore: auto_pass_min_score || MIN_SIMILARITY_SCORE,
    category: category ? category.toString() : '',
    entityTypes: newVersion.legalTypes || [],
    maxCreditValue: toNumber(newVersion.maxCreditValue),
    minCreditValue: toNumber(newVersion.minCreditValue),
    tokenised: typeof tokenised === 'undefined' ? false : tokenised,
    autoPassMinConfidenceScore:
      auto_pass_min_confidence_score || MIN_CONFIDENCE_SCORE,
  };

  const {
    clearError,
    errors,
    formState,
    handleSubmit,
    register,
    setValue,
    triggerValidation,
    watch,
    getValues,
  } = useForm({
    defaultValues,
    mode: 'onBlur',
    reValidateMode: 'onSubmit',
    validationSchema: getFormSchema({ tradeAccountLimit }),
  });

  const values = watch() as Rule;
  const { isValid } = formState;

  const isManualCategory = !values.category || values.category === '1';
  const isDefaultCategory = !values.category || values.category === '0';

  /* eslint-disable sort-keys-fix/sort-keys-fix */
  const tabs = {
    website: 'Website channel',
    qr: 'Location channel',
    sales: 'Rep channel',
  };
  /* eslint-enable sort-keys-fix/sort-keys-fix */

  function setDirtyState() {
    const values = getValues() as Obj;
    const isDirty = Object.keys(values).some((key) => {
      const value = values[key];
      const defaultValue = defaultValues[key];

      if (typeof value !== 'object') {
        return value !== defaultValue;
      }
      const stringCurrentValue = Object.entries(value || {}).toString();
      const stringDefaultValue = Object.entries(defaultValue || {}).toString();

      return stringCurrentValue !== stringDefaultValue;
    });

    setDirty(isDirty);
  }

  const currentAntiFraudValues = currentConfig?.addonVersions[0].attributes;

  const matchingAutoDecisionRulesets = useMemo(
    () =>
      getMatchingAutoDecisionRulesets(
        currentAntiFraudValues,
        autoDecisionRulesets
      ),
    [currentAntiFraudValues, autoDecisionRulesets]
  );

  const checkImpactOnAutoDecision = (newAntiFraudSettings: Rule) => {
    const {
      accountTypes: newAccountTypes = [],
      authorisationTypes: newAuthorisationTypes,
      category: newCategory,
      entityTypes: newEntityTypes,
      minCreditValue: newMinCreditvalue,
      maxCreditValue: newMaxCreditValue,
    } = newAntiFraudSettings;

    const onConfirm = () => submit(newAntiFraudSettings);

    const autoDecisionRulesetsThatWillBeImpacted =
      matchingAutoDecisionRulesets.filter((ruleset) => {
        const {
          application_types,
          legal_types: rulesetEntityTypes,
          min_credit_value: rulesetMinCreditValue,
          max_credit_value: ruleset_max_credit_value,
        } = ruleset.attributes;

        const rulesetApplicationType = application_types[0];

        // Check if there is atleast one account type in the auto decision ruleset
        // that is not covered in the changed anti fraud settings.
        const isAccountTypeRemoved = !newAccountTypes.includes(
          rulesetApplicationType
        );

        // Check if there is atleast one entity type in the auto decision ruleset
        // that is not covered in the changed anti fraud settings.
        const isEntityTypeRemoved = rulesetEntityTypes.some(
          (rulesetEntityType) => !newEntityTypes.includes(rulesetEntityType)
        );

        // Now we'll check if user has changed the credit limit that could impact
        // a ruleset. Let's begin by assuming that there's no impact.
        let isCreditValueImpacted = false;
        if (
          rulesetApplicationType === 'credit' &&
          isValuePresent(newMinCreditvalue) &&
          typeof newMinCreditvalue === 'number' &&
          isValuePresent(newMaxCreditValue) &&
          typeof newMaxCreditValue === 'number'
        ) {
          isCreditValueImpacted =
            newMinCreditvalue > rulesetMinCreditValue ||
            newMaxCreditValue < rulesetMinCreditValue;
        }

        // The auto decision will be impacted if 'Applicant' is removed from the
        // authoriosation types.
        const isAuthorisationTypeImapcted =
          !newAuthorisationTypes.includes('applicant');

        return (
          isAccountTypeRemoved ||
          isEntityTypeRemoved ||
          isCreditValueImpacted ||
          isAuthorisationTypeImapcted
        );
      });

    if (!autoDecisionRulesetsThatWillBeImpacted.length) {
      // There is no ruleset that will be impacted.
      // Let's proceed to make the change that the user wants.

      onConfirm();
      return;
    }

    setAutoDecisionImpact({
      isAutoDecisionImpactModalVisible: true,
      onConfirm,
      rulesetsThatWillBeImpacted: autoDecisionRulesetsThatWillBeImpacted,
      tabStatusToBePaused: 'validation_status',
    });
  };

  const handleConfirmAutoDecisionImpact = () => {
    autoDecisionImpact.onConfirm && autoDecisionImpact.onConfirm();

    const { rulesetsThatWillBeImpacted, tabStatusToBePaused } =
      autoDecisionImpact;
    rulesetsThatWillBeImpacted.forEach((ruleset) => {
      // Let's pause the auto decision rules
      const { attributes, id } = ruleset;

      const updatedAttributes = {
        ...attributes,
        ...(tabStatusToBePaused !== null
          ? { [tabStatusToBePaused]: 'paused' }
          : {}),
      };
      dispatch(updateRuleSet(id, updatedAttributes));
    });

    setAutoDecisionImpact({
      isAutoDecisionImpactModalVisible: false,
      onConfirm: null,
      rulesetsThatWillBeImpacted: [],
      tabStatusToBePaused: null,
    });
  };

  const handleCancelAutoDecisionImpact = () => {
    setAutoDecisionImpact({
      isAutoDecisionImpactModalVisible: false,
      onConfirm: null,
      rulesetsThatWillBeImpacted: [],
      tabStatusToBePaused: null,
    });
  };

  function submit(data: Rule) {
    if (readOnly) {
      return;
    }

    setIsLoading(true);
    const attributes = getNewVersionAttribute(addonConfig, data, activeTab);
    newVersion.setAttributes(attributes);
    newVersion.save({
      addonConfig,
      currentUser,
      onErrorCallback: () =>
        setStatus({
          message:
            'Oops! Something is wrong. Please try again or contact us if the problem still persists.',
          type: 'danger',
        }),
      onSuccessCallback,
    });
  }

  function onSuccessCallback(addonVersion) {
    mixpanel.track('1CAF settings updated', {
      'Entity ID': get(currentUser, 'currentEntity.id'),
      Ruleset: addonVersion.data,
      distinct_id: currentUser.id,
    });

    setDirty(false);
    setIsDirtyPopupVisible(false);
    setIsLoading(false);
    handleDiscard();
    dispatch(loadAntiFraud());
    setStatus({
      message: 'Your changes have been saved successfully.',
      type: 'success',
    });
    dispatch(updateMandatoryChecks({}));
  }

  function _setValue(key: string, value) {
    setValue(key, value);
    triggerValidation(key);
    setDirtyState();

    if (status) {
      setStatus(null);
    }
  }

  function handleDiscard() {
    setIsDirtyPopupVisible(false);

    if (dirtyPopUpCallback) {
      reset();
      dirtyPopUpCallback();
      setDirty(false);
      setDirtyPopUpCallback(null);
    }

    forceUpdate();
    clearError();
  }

  function checkDirtyBeforeAction(action: () => void) {
    if (dirty) {
      setDirtyPopUpCallback(() => action);
      return setIsDirtyPopupVisible(true);
    }

    action();
    forceUpdate();
    clearError();
  }

  function onChangeTab(tabName: string) {
    const action = () => {
      setActiveTab(tabName);
    };
    checkDirtyBeforeAction(action);
  }

  function onVisitProfilePage() {
    const action = () => {
      router.push(SET_1CAH_PATH);
    };
    checkDirtyBeforeAction(action);
  }

  function handleClose() {
    const action = () => closeModal();
    checkDirtyBeforeAction(action);
  }

  function creditCardSaveCallback(response) {
    if (response) {
      dispatch(loadCard());
      handleCloseCreditModal();
    }
  }

  function handleCloseCreditModal() {
    setCardModal(false);
  }

  function handleEdit() {
    setIsDirtyPopupVisible(false);
  }

  function handleTokenisedChange(e) {
    const value = e.target.value;
    _setValue('tokenised', value === 'Yes');
  }

  function handleCategoryChange(key: string, e) {
    const value = e.target.value;
    if (
      isCat0Enabled
        ? value !== '0' && value !== '1' && !card && isRegular()
        : value !== '1' && !card && isRegular()
    ) {
      return setCardModal(true);
    }
    _setValue(key, value);
  }

  function handleEntityTypeChange(e: { value: string[] }) {
    _setValue('entityTypes', e.value);
  }

  function handleAccountTypeChange(e: { value: string[] }) {
    _setValue('accountTypes', e.value);
  }

  function handleAuthorisationTypeChange(e: { value: string[] }) {
    _setValue('authorisationTypes', e.value);
  }

  function handleLimitChange(key: string, value: string) {
    const processedValue = toNumber(value);
    _setValue(key, processedValue);
    triggerValidation(['minCreditValue', 'maxCreditValue']);
  }

  function handleAutopassMinScoreChange(e) {
    const processedValue = toNumber(e.target.value);
    _setValue('autoPassMinScore', processedValue);
  }

  function handleAutopassMinConfidenceScoreChange(e) {
    const processedValue = toNumber(e.target.value);
    _setValue('autoPassMinConfidenceScore', processedValue);
  }

  function reset() {
    setStatus(null);
    return Object.keys(defaultValues).map(
      (key) => setValue(key, defaultValues[key]),
      { shouldDirty: false }
    );
  }

  useEffect(() => {
    const fieldsRegistration = [
      'authorisationTypes',
      'category',
      'entityTypes',
      'minCreditValue',
      'maxCreditValue',
      'autoPassMinScore',
      'tokenised',
      ...addFlaggedArray('FEATURE_FLAG_1CAF_ACCOUNT_TYPES', ['accountTypes']),
      ...addFlaggedArray('FEATURE_FLAG_1CAF_CAT_6', [
        'autoPassMinSimilarityScore',
        'autoPassMinConfidenceScore',
      ]),
    ];

    fieldsRegistration.forEach((name) => register({ name }));
  }, []);

  useEffect(() => {
    reset();
  }, [activeTab]);

  const requiresFrontFaceImage = values.category
    ? getCategoriesRequiringFrontFaceImage().includes(values.category)
    : false;

  const requiresProofOfLife = values.category
    ? getCategoriesRequiringProofOfLife().includes(values.category)
    : false;

  return (
    <MuiThemeProvider theme={muiTheme()}>
      {cardModal && (
        <CreditCardModal
          onSave={creditCardSaveCallback}
          onCancel={handleCloseCreditModal}
        />
      )}
      {isDirtyPopupVisible && (
        <UnsavedModal
          loading={isLoading}
          handleEdit={handleEdit}
          handleDiscard={handleDiscard}
          handleSave={handleSubmit(checkImpactOnAutoDecision)}
          isValid={isValid}
        />
      )}
      {autoDecisionImpact.isAutoDecisionImpactModalVisible && (
        <ImpactAutoDecisionRulesetPopup
          onConfirm={handleConfirmAutoDecisionImpact}
          onCancel={handleCancelAutoDecisionImpact}
          rulesetNames={getRulesetNames(
            autoDecisionImpact.rulesetsThatWillBeImpacted
          )}
        />
      )}
      <Wrapper>
        <div className="container">
          <div className="column">
            <Section>
              <Title>1Centre Anti-Fraud (1CAF)</Title>
              <p>
                1Centre Anti-Fraud gives you the ability of setting multiple
                identification parameters that meet your personalised
                &apos;Identity Risk Profile&apos;.{' '}
                {isCat0Enabled
                  ? `Work through each channel below to set up the identity layers you require.`
                  : `All of your channels will
                automatically default to Cat 1; from there you have the option
                to increase your checking methods (Cat1-4) by channel. Work
                through each channel below to set up the identity layers you
                require.`}
              </p>
              <CloseButton
                handleClick={handleClose}
                style={{ right: 0, top: 0 }}
              />

              <Tabs>
                {Object.keys(tabs).map((key) => (
                  <Tab
                    type="button"
                    key={key}
                    onClick={() => onChangeTab(key)}
                    active={key === activeTab}
                    tabIndex={0}
                  >
                    {tabs[key]}
                  </Tab>
                ))}
              </Tabs>
            </Section>

            <form onSubmit={handleSubmit(checkImpactOnAutoDecision)}>
              <ContentContainer
                header="Identity risk methods"
                description="Select the risk category for this sales channel, that best meets your business requirements.  "
              >
                <CategoryRadiobox
                  disabled={readOnly}
                  handleChange={(e) => handleCategoryChange('category', e)}
                  value={values.category}
                  error={!!errors.category}
                  helperText={get(errors, 'category.message')}
                  dataCy="antifraud"
                />
              </ContentContainer>

              {FEATURE_FLAGS.FEATURE_FLAG_1CAF_ACCOUNT_TYPES && (
                <ContentContainer
                  header="Account types"
                  description={
                    "Select the account types that will apply the identity check method you've selected. 1CAF does not apply to any unticked account types."
                  }
                >
                  <div className="columns">
                    <div className="column is-4 mb-2">
                      <AccountTypeSelectDropdown
                        bulkSelect
                        disabled={readOnly}
                        handleChange={handleAccountTypeChange}
                        multiple
                        value={values.accountTypes}
                        error={get(errors, 'accountTypes.message')}
                        options={getAccountTypeOptions()}
                        dataCy="antifraud-account-type-dropdown"
                      />
                    </div>
                  </div>
                </ContentContainer>
              )}

              <ContentContainer
                header="Entity types"
                description={
                  "Select those entities that need to complete the identity\
                  check method you've selected. 1CAF does not apply to any\
                  unticked entity types."
                }
              >
                <div className="columns">
                  <div className="column is-4 mb-2">
                    <EntityTypeSelectDropdown
                      bulkSelect
                      disabled={readOnly}
                      handleChange={handleEntityTypeChange}
                      multiple
                      value={values.entityTypes || []}
                      error={get(errors, 'entityTypes.message')}
                      dataCy="antifraud-entity-type-dropdown"
                    />
                  </div>
                </div>
              </ContentContainer>

              <ContentContainer
                header="Authorisation types"
                description={
                  isCat0Enabled
                    ? "Select those authorisations that need to complete the\
                       identity check method you've selected. 1CAF does not apply to\
                       any unticked authorisation types."
                    : "Select those authorisations that need to complete the\
                       identity check method you've selected. Any others\
                       (if applicable) will default to Cat.1.\
                       This only applies to ticked entity types in Step 2."
                }
                visible={isCat0Enabled ? true : !isManualCategory}
              >
                <div className="columns">
                  <div className="column is-4 mb-2">
                    <AuthorisationTypeSelectDropdown
                      bulkSelect
                      disabled={readOnly}
                      handleChange={handleAuthorisationTypeChange}
                      multiple
                      value={values.authorisationTypes || []}
                      error={get(errors, 'authorisationTypes.message')}
                      isAML={values.category === '5'}
                      dataCy="antifraud-authorisation-type-dropdown"
                    />
                  </div>
                </div>
              </ContentContainer>

              <ContentContainer
                header="Tokenisation"
                description={
                  '\
                  Enable tokenisation will display a token as the identification\
                  in the Virtual Credit File. This will also remove any uploaded\
                  identification files from the database after the application is\
                  approved/declined. Would you like to enable tokenisation?\
                '
                }
                visible={isCat0Enabled ? !isDefaultCategory : true}
              >
                <Radiobox
                  id="tokenised"
                  disabled={readOnly}
                  radioList={['Yes', 'No']}
                  display="row"
                  handleChange={handleTokenisedChange}
                  value={values.tokenised}
                  error={!!errors.tokenised}
                  helper_text={get(errors, 'tokenised.message')}
                  dataCy="antifraud"
                />
              </ContentContainer>

              <ContentContainer
                header="Applicable limit range (optional)"
                description={
                  <p>
                    {`Where a limit range is disclosed, only applications that
                    fall within the range will be asked for ID verifications
                    based on the selected identity check method.${
                      isCat0Enabled
                        ? ''
                        : ` Cat.1 will apply to all applications outside the limit range.`
                    } `}{' '}
                    {!tradeAccountLimit && (
                      <span className="has-text-warning">
                        Please set your application values &amp; approval levels
                        in order to access this settings. Click{' '}
                        <a onClick={onVisitProfilePage}>here</a> to set it up -
                        profile tab &gt; your team &gt; set application values &
                        approval levels.
                      </span>
                    )}
                  </p>
                }
                visible={isCat0Enabled ? !isDefaultCategory : !isManualCategory}
              >
                <div className="columns">
                  <div className="column is-3">
                    <BorderedTextField
                      placeholder="min"
                      data-cy="antifraud-min-limit-text-field"
                      customProps={{
                        startAdornment: (
                          <InputAdornment position="start">$</InputAdornment>
                        ),
                      }}
                      value={_formatMoney(values.minCreditValue)}
                      onChange={(e) =>
                        handleLimitChange('minCreditValue', e.target.value)
                      }
                      error={!!errors.minCreditValue}
                      helperText={get(errors, 'minCreditValue.message')}
                      disabled={readOnly || !tradeAccountLimit}
                    />
                  </div>
                  <div className="column is-1 has-text-centered">
                    <span
                      style={{ display: 'inline-block', paddingTop: '0.5rem' }}
                    >
                      &mdash;
                    </span>
                  </div>
                  <div className="column is-3">
                    <BorderedTextField
                      placeholder="max"
                      data-cy="antifraud-max-limit-text-field"
                      customProps={{
                        startAdornment: (
                          <InputAdornment position="start">$</InputAdornment>
                        ),
                      }}
                      value={_formatMoney(values.maxCreditValue)}
                      onChange={(e) =>
                        handleLimitChange('maxCreditValue', e.target.value)
                      }
                      error={!!errors.maxCreditValue}
                      helperText={get(errors, 'maxCreditValue.message')}
                      disabled={readOnly || !tradeAccountLimit}
                    />
                  </div>
                </div>
              </ContentContainer>

              <ContentContainer
                header="Auto-pass minimum Similarity Score"
                description={`Set the minimum Similarity Score required for the facial recognition\
                  imagery to pass/fail. The system will compare the ID image with the FaceMatch or\
                  Proof of life imagery, anything below the minimum score will require a manual check.\
                  If a similarity score is less than or equal to 80%, the system will prompt the customer\
                  to retake their photo.
                `}
                visible={requiresFrontFaceImage || requiresProofOfLife}
              >
                <div className="columns">
                  <div className="column is-3">
                    <BorderedTextField
                      disabled={readOnly}
                      data-cy="antifraud-autopass-minimum-similarity-score-text-field"
                      placeholder="min"
                      customProps={{
                        endAdornment: (
                          <InputAdornment position="end">%</InputAdornment>
                        ),
                      }}
                      inputProps={{
                        max: MAX_SIMILARITY_SCORE,
                        type: 'number',
                      }}
                      value={values.autoPassMinScore}
                      onChange={handleAutopassMinScoreChange}
                      error={!!errors.autoPassMinScore}
                      helperText={get(errors, 'autoPassMinScore.message')}
                    />
                  </div>
                </div>
              </ContentContainer>

              <ContentContainer
                header="Auto-pass minimum Confidence Score"
                description={`Set the minimum Confidence Score (between 30-100)
                  required for Proof of life to pass/fail. The higher the score,
                  the greater the confidence that the person taking the check is
                  live. Anything below the minimum score will require a manual check.
                  We recommend using at least above the moderate threshold 50.`}
                visible={requiresProofOfLife}
              >
                <div className="columns">
                  <div className="column is-3">
                    <BorderedTextField
                      disabled={readOnly || !isCat6Enabled}
                      data-cy="antifraud-autopass-minimum-confidence-score-text-field"
                      placeholder="min"
                      customProps={{
                        endAdornment: (
                          <InputAdornment position="end">%</InputAdornment>
                        ),
                      }}
                      inputProps={{
                        max: MAX_CONFIDENCE_SCORE,
                        type: 'number',
                      }}
                      value={values.autoPassMinConfidenceScore}
                      onChange={handleAutopassMinConfidenceScoreChange}
                      error={!!errors.autoPassMinConfidenceScore}
                      helperText={get(
                        errors,
                        'autoPassMinConfidenceScore.message'
                      )}
                    />
                  </div>
                </div>
              </ContentContainer>

              {status && <FormSubmissionStatus {...status} />}
              <Button
                text="save"
                type="submit"
                disabled={!dirty}
                loading={isLoading}
                data-cy="antifraud-submit-button"
              />
            </form>
            <HistoryLogs addonVersions={addonConfig.addonVersions} />
          </div>
        </div>
      </Wrapper>
    </MuiThemeProvider>
  );
}

export default connect((state) => {
  const currentUser = UserModel.fromCurrentUser(state.current_user);
  const tradeAccountLimit = get(currentUser, 'currentEntity.tradeAccountLimit');
  const card = !!state.manage_profile.current_entity_card;
  const autoDecisionRulesets = state.add_ons.auto_decisions;

  return {
    card,
    currentUser,
    readOnly: !isFeatureEditEnabled('Credit'),
    tradeAccountLimit,
    autoDecisionRulesets,
  };
})(AntiFraud);
